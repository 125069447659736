@import '~normalize.css/normalize.css';

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

::-webkit-input-placeholder {
  opacity: 1;
}

.ant-menu.ant-menu-horizontal {
  border-bottom: none;
  background-color: transparent;
}

img {
  max-width: 100%;
}

.ant-alert-info {
  background-color: #9addfb;
  border: 1px solid #3ab6f0;
}
